<template>
  <div class="login-container">
    <template v-if="showSso">
      <SSO @cancel="toggleShowSso" />
    </template>
    <template v-else-if="showVerifyEmail">
      <FriedParagraph class="branding">{{ $t('login-verify-email-subheader') }}</FriedParagraph>
      <FriedH2 class="branding login-title">{{ $t('login-verify-email-header') }}</FriedH2>
      <FriedParagraph size="small" class="verify-email-sent-message">
        {{ `${$t('login-verify-email-sent-message')}  ${verificationEmail}` }}
      </FriedParagraph>
      <VerifyEmail class="verify-email-svg" />
    </template>
    <template v-else-if="requireOTPVerification">
      <div class="otp-verify-container">
        <FriedH2>{{ $t('otp_verify_your_account') }}</FriedH2>
        <FriedParagraph class="otp-info-message">
          {{ $t('otp_login_please_verify_your_email') }}
        </FriedParagraph>
        <div>
          <FriedLabel for="pin">{{ $t('otp_verify_account_pin_label') }}</FriedLabel>
          <Pin id="pin" :pin-length="6" @pin="pin = $event" />
          <FriedMessage
            v-if="error"
            type="error"
            data-external="otp-verify-error-div"
            class="error"
            :title="$t(error.description)"
          />
          <template v-else>
            {{ $t('otp_verify_help_message') }}
            <FriedLink @click="generateNewLoginOTP">{{ $t('resend_code') }}</FriedLink>
            <FriedMessage
              v-if="newLoginOTPCode"
              class="resend-code-info"
              type="info"
              data-external="otp-verify-info-div"
            >
              {{ $t('otp_verify_resend_code_info') }}</FriedMessage
            >
          </template>
          <div class="action-buttons">
            <FriedButton
              button-type="secondary"
              :disabled="loading"
              data-external="otp-cancel-button"
              @click="cancelOTP"
              >{{ $t('cancel') }}</FriedButton
            >
            <FriedButton
              :loading="loading"
              data-external="otp-verify-button"
              :disabled="pin.length !== 6"
              @click="handleVerifyLoginOTP"
            >
              {{ $t('verify') }}
            </FriedButton>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div>
        <FriedParagraph class="branding">{{ $t('login') }}</FriedParagraph>
        <FriedH2 class="branding login-title">{{ $t('welcome-back') }}</FriedH2>
      </div>
      <FriedMessage
        v-if="error"
        type="error"
        data-external="login-error-div"
        class="error"
        :title="$t(error.title)"
      >
        <i18n-t :keypath="error.description">
          <template #contactSupportLink>
            <FriedLink :normal="true" href="http://help.getaccept.com/" target="_blank">
              {{ $t('contact-support') }}
            </FriedLink>
          </template>
        </i18n-t>
      </FriedMessage>
      <div class="social-login-container">
        <FriedButton
          data-external="login-sign-in-with-google-button"
          class="identity-provider-btn"
          button-type="tertiary"
          @click="handleProviderClick(LoginProvider.Google)"
        >
          <template #leftIcon>
            <GoogleIcon class="icon" />
          </template>
          {{ $t('sign-in-with-google') }}
        </FriedButton>
        <FriedButton
          class="identity-provider-btn"
          button-type="tertiary"
          data-external="login-sign-in-with-linked-button"
          @click="handleProviderClick(LoginProvider.LinkedIn)"
        >
          <template #leftIcon>
            <LinkedInIcon class="icon" />
          </template>
          {{ $t('sign-in-with-linkedin') }}
        </FriedButton>
        <FriedButton
          class="identity-provider-btn"
          button-type="tertiary"
          data-external="login-sign-in-with-SSO"
          @click="toggleShowSso(true)"
        >
          <template #leftIcon>
            <FriedIcon icon="user-lock" />
          </template>
          {{ $t('sign-in-with-sso') }}
        </FriedButton>
      </div>
      <FriedParagraph class="or-divider">{{ $t('or') }}</FriedParagraph>
      <form @submit.prevent="handleSubmit">
        <FriedInput
          v-model.trim="email"
          data-external="login-email-input"
          type="text"
          name="email"
          :label="$t('email')"
          class="login-input"
          :error-message="emailError"
          :delayed-focus="true"
          autocomplete="email"
        />
        <FriedInput
          v-model="password"
          data-external="login-password-input"
          :type="passwordInputType"
          name="password"
          :label="$t('password')"
          class="login-input margin"
          :error-message="passwordError"
          autocomplete="current-password"
        >
          <template #append>
            <button
              data-external="login-toggle-password-button"
              type="button"
              tabindex="-1"
              class="view-password-button"
              :title="$t('show-and-hide-password')"
              @click.prevent="toggleViewPassword"
            >
              <FriedIcon v-if="!viewPassword" key="1" aria-hidden icon="view" />
              <FriedIcon v-else key="2" aria-hidden icon="view-hide" />
            </button>
          </template>
        </FriedInput>
        <FriedButton
          class="submit-button login-button margin"
          data-external="login-submit-button"
          :loading="loading"
          button-type="branding"
          type="submit"
        >
          {{ $t('login') }}
        </FriedButton>
      </form>
      <div>
        <RouterLink :to="{ name: 'request' }">
          <FriedLink data-external="login-forget-password-link" class="login-link">
            {{ $t('forgot-password') }}
          </FriedLink>
        </RouterLink>
        <FriedParagraph>
          {{ $t('dont-have-an-account-yet') }}
          <FriedLink data-external="login-sign-up-link" class="login-link" href="/signup?ref=login">
            {{ $t('sign-up') }}
          </FriedLink>
        </FriedParagraph>
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { InputType } from '@getaccept/fried-tofu';
import { sanitizeEmail, validateEmail } from '@getaccept/lib-shared/src/helpers';
import { LocalStorageKey } from '@getaccept/lib-shared/src/enums/local-storage';
import { LocalStorageHelper } from '@getaccept/lib-shared/src/helpers/local-storage.helper';
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import Pin from '@getaccept/lib-shared/src/components/Pin.vue';
import GoogleIcon from '../assets/google.svg?component';
import LinkedInIcon from '../assets/linkedin.svg?component';
import VerifyEmail from '../assets/verify-email/VerifyEmail.svg?component';
import { PathChecker } from '../../helpers/path-checker';
import { LoginProvider } from '../types/enums/login-provider';
import { useLogin } from '../composable/login.composable';
import SSO from './SSO.vue';

const {
  login,
  error,
  launchIdentifier,
  loading,
  goUrl,
  verifyLoginOTP,
  requireOTPVerification,
  cancelOTP,
  generateNewLoginOTP,
  newLoginOTPCode,
} = useLogin();
const { t } = useI18n();
const route = useRoute();

const setGoUrl = () => {
  goUrl.value = route.query.go ? `${route.query.go}` : route.fullPath;
};

const password = ref('');
const passwordError = ref('');
const viewPassword = ref(false);
const pin = ref('');

watch(password, () => {
  passwordError.value = '';
});

const passwordInputType = computed(() =>
  viewPassword.value ? InputType.Text : InputType.Password
);

const toggleViewPassword = () => {
  viewPassword.value = !viewPassword.value;
};

const validatePassword = (password: string) => {
  if (!password) {
    return t('password-is-required').toString();
  }
  return '';
};

const validateEmailAddress = (email: string): string => {
  if (!email) {
    return t('email-is-required').toString();
  } else if (!validateEmail(sanitizeEmail(email))) {
    return t('enter-a-valid-email').toString();
  }
  return '';
};

const showVerifyEmail = ref(false);
const email = ref('');
const emailError = ref('');

watch(email, () => {
  emailError.value = '';
});

const verificationEmail = computed(() =>
  LocalStorageHelper.getLocalStorage(LocalStorageKey.VerificationEmail)
);

const showSso = ref(false);
const toggleShowSso = (show: boolean) => {
  showSso.value = show;
};

const handleVerifyLoginOTP = () => {
  if (pin.value.length !== 6) {
    return;
  }
  verifyLoginOTP(pin.value);
};

const handleProviderClick = (provider: LoginProvider) => {
  launchIdentifier(provider);
};

const handleSubmit = () => {
  const cleanEmail = sanitizeEmail(email.value);
  passwordError.value = validatePassword(password.value);
  emailError.value = validateEmailAddress(cleanEmail);
  if (passwordError.value || emailError.value) {
    return;
  }
  login({ email: cleanEmail, password: password.value });
};

onMounted(() => {
  setGoUrl();
  showVerifyEmail.value = PathChecker.checkLoginVerifyEmailPaths(route.path);
});

onBeforeUnmount(() => {
  LocalStorageHelper.reset(LocalStorageKey.VerificationEmail);
});
</script>

<style lang="scss" scoped>
.login-container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-100);

  @media screen and (min-width: $sm) {
    height: 100%;
    justify-content: space-between;
  }

  @media screen and (max-width: $sm) {
    gap: var(--spacing-200);
  }

  .social-login-container {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-50);
  }

  .login-input,
  .login-button {
    width: 100%;
  }

  .margin {
    margin: var(--spacing-50) 0;
  }

  .or-divider {
    width: 100%;
    text-align: center;
    display: flex;
    gap: 1rem;
    align-items: center;
    color: var(--gray-39);

    &::after,
    &::before {
      content: '';
      flex: 1 1 auto;
      border-bottom: solid 1px var(--gray-89);
    }
  }

  .login-link {
    margin-bottom: var(--spacing-50);
    font-weight: 600;
  }

  .maintenance {
    border-color: var(--base-blue);
  }

  .view-password-button {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    height: 2.5rem;
    width: 2.5rem;

    &:focus:not([data-focus-visible-added]) {
      outline: none;
    }
  }

  .verify-email-svg {
    display: block;
    margin: 0 auto var(--spacing-150) auto;
  }
}

.sso-button-container {
  display: flex;
  gap: var(--spacing-75);
  margin-top: var(--spacing-50);

  @media screen and (max-width: calc($xxs - 100px)) {
    flex-wrap: wrap;
  }
}

.otp-verify-container {
  .otp-info-message {
    display: block;
    margin: var(--spacing-150) 0;
  }

  .action-buttons {
    display: flex;
    gap: var(--spacing-50);
    margin: var(--spacing-150) 0;
    justify-content: right;
  }

  .resend-code-info {
    margin-top: var(--spacing-50);
  }
}
</style>
