import type { Client } from '@bugsnag/js';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import { ApolloLink } from '@apollo/client/core';
import type { OperationDefinitionNode } from 'graphql';

let bugsnagClient: Client | null = null;
if (import.meta.env.VUE_APP_BUGSNAG && import.meta.env.MODE !== 'test') {
  let environment = import.meta.env.MODE || 'development';
  if (environment === 'prod') {
    environment = 'production';
  } else if (environment === 'dev') {
    environment = 'production-dev';
  } else if (environment === 'stage') {
    environment = 'staging';
  }
  bugsnagClient = Bugsnag.start({
    apiKey: import.meta.env.VUE_APP_BUGSNAG,
    releaseStage: import.meta.env.VUE_APP_BUGSNAG_RELEASE_STAGE || environment,
    appVersion: import.meta.env.VUE_APP_BUGSNAG_APP_VERSION,
    appType: import.meta.env.VUE_APP_BUGSNAG_APP_TYPE,
    enabledErrorTypes: {
      unhandledExceptions: true,
      unhandledRejections: false,
    },
    plugins: [new BugsnagPluginVue()],
  });
}

export const breadcrumbLink = new ApolloLink((operation, forward) => {
  if (bugsnagClient) {
    bugsnagClient.leaveBreadcrumb(
      `GraphQL ${(operation.query.definitions[0] as OperationDefinitionNode).operation}: "${
        operation.operationName
      }"`,
      {
        name: operation.operationName,
        type: (operation.query.definitions[0] as OperationDefinitionNode).operation,
        variables: operation.variables,
      }
    );
  }
  return forward(operation);
});

export default bugsnagClient;
